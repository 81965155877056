// https://github.com/prismicio/gatsby-multi-language-site/blob/master/src/utils/linkResolver.js

const {
  eventPath,
  homePath,
  eventsPath,
  investmentPath,
  contactPath,
  customPagePath,
  teamPath,
  portfolioPath,
  investmentsPath,
} = require('../paths')

/* The Link Resolver
* This function will be used to generate links to Prismic documents
As your project grows, you should update this function according to your routes */

const linkResolver = (doc) => {
  const properties = doc._meta || doc

  if (['homepage', 'links', 'link', 'settings', 'highlight'].includes(properties.type)) {
    return homePath()
  }

  if (['events', 'speaker'].includes(properties.type)) {
    return eventsPath()
  }

  if (properties.type === 'event') {
    return eventPath(properties.uid)
  }

  if (properties.type === 'company') {
    return investmentPath(properties.uid)
  }

  if (properties.type === 'investments') {
    return investmentsPath(properties.uid)
  }

  if (properties.type === 'contact') {
    return contactPath()
  }

  if (properties.type === 'portfolio') {
    return portfolioPath()
  }

  if (['team', 'team_member'].includes(properties.type)) {
    return teamPath()
  }

  if (properties.type === 'custom_page') {
    return customPagePath(properties.uid)
  }

  const debugParams = new URLSearchParams({
    status: 'unkown',
    type: properties.type,
    uid: properties.uid,
  })

  return `/?${debugParams}` // TODO error?
}

module.exports = linkResolver
