import classNames from 'classnames'
import React from 'react'
import * as styles from './details-box.module.scss'

const DetailsBox = ({
  className,
  dark,
  children,
}: {
  className?: string
  dark?: boolean
  children: React.ReactNode
}) => {
  return (
    <div
      className={classNames(styles.detailsBox, className, {
        [styles.dark]: dark,
      })}
    >
      {children}
    </div>
  )
}

export default DetailsBox
