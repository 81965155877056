// extracted by mini-css-extract-plugin
export const teamMember = "team-module--teamMember--3voPQ";
export const sidebar = "team-module--sidebar--RTbf2";
export const bio = "team-module--bio--1Hemo";
export const partial = "team-module--partial--KSsWQ";
export const photo = "team-module--photo--Blcem";
export const socialLinks = "team-module--socialLinks--2q469";
export const socialLink = "team-module--socialLink--3JD9L";
export const contactButton = "team-module--contactButton--2J40B";
export const readFullButton = "team-module--readFullButton--3_4mh";
export const highlights = "team-module--highlights--2unb3";