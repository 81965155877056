import withStyles from '@material-ui/core/styles/withStyles'
import MuiTextField, { StandardTextFieldProps } from '@material-ui/core/TextField'
import React from 'react'

const StyledTextField = withStyles({
  root: {
    display: 'flex',
    marginTop: 0,
    '& label.Mui-focused': {
      color: '#272727',
    },
    '& .MuiFormLabel-root': {
      color: '#979797',
      fontSize: '0.875rem',
    },
    '& .MuiInput-underline:after': {
      borderWidth: 1,
      borderBottomColor: '#272727',
    },
  },
})(MuiTextField)

const TextField = (props: StandardTextFieldProps) => {
  return <StyledTextField margin="normal" autoComplete="off" {...props} />
}

export default TextField
