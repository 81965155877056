import classNames from 'classnames'
import { graphql } from 'gatsby'
import { withPreview } from 'gatsby-source-prismic'
import { RichText } from 'prismic-reactjs'
import React, { useState } from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'
import Highlights from 'src/components/highlights'
import LinkArrow from 'src/components/link-arrow'
import ResponsiveImage from 'src/components/responsive-image'
import TeamContactModal from 'src/components/team-contact-modal'
import { contactPath } from 'src/paths'
import ArrowRight from '../assets/arrow-right.svg'
import Facebook from '../assets/facebook.svg'
import Instagram from '../assets/instagram.svg'
import LinkedIn from '../assets/linkedin.svg'
import Twitter from '../assets/twitter.svg'
import DetailsBox from '../components/details-box'
import Layout from '../components/layout'
import { BbvPageProps, PrismicTeamProps, TeamMember } from '../types'
import GatsbyLink from '../ui/GatsbyLink'
import * as styles from './team.module.scss'

export const query = graphql`
  query TeamQuery {
    prismicTeam {
      data {
        title {
          text
          raw
        }
        contact_success_message
        team_member_list {
          team_member {
            document {
              ... on PrismicTeamMember {
                uid
                type
                data {
                  name {
                    text
                  }
                  job_title
                  company_name
                  email
                  bio {
                    raw
                    text
                  }
                  facebook {
                    url
                  }
                  linkedin {
                    url
                  }
                  twitter {
                    url
                  }
                  instagram {
                    url
                  }
                  photo {
                    thumbnails {
                      small {
                        url
                        alt
                        dimensions {
                          height
                          width
                        }
                      }
                    }
                  }
                  highlights {
                    highlight {
                      document {
                        ...highlight
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    prismicSettings {
      ...settings
    }
    prismicLinks {
      ...links
    }
  }
`

const TeamMemberItem = ({
  uid,
  teamMember,
  contactSuccessMessage,
  contactZapierUrl,
}: {
  uid: string
  teamMember: TeamMember
  contactSuccessMessage: string
  contactZapierUrl?: string
}) => {
  const bioParagraphs = teamMember.bio.raw
  const [showAll, setShowAll] = useState(bioParagraphs.length <= 2)
  const [showContactModal, setShowContactModal] = useState(false)

  const toggleContactModal = () => setShowContactModal((prev) => !prev)
  const showPartialBio = !showAll && bioParagraphs.length > 2
  const highlights = teamMember.highlights
    .map((highlight) => highlight.highlight.document)
    .filter((h) => h)

  return (
    <div className={styles.teamMember}>
      <Row className="flex-column-reverse flex-sm-row">
        <Col xs={12} sm={6} md={8}>
          <h2 className="h1">{teamMember.name.text}</h2>
          <div
            className={classNames('body-text', styles.bio, { [styles.partial]: showPartialBio })}
          >
            <RichText
              render={showPartialBio ? teamMember.bio.raw.slice(0, 2) : bioParagraphs}
              serializeHyperlink={GatsbyLink}
            />
          </div>

          {showPartialBio && (
            <>
              <Button
                onClick={() => setShowAll(true)}
                variant="dark"
                size="lg"
                block={true}
                className={styles.readFullButton}
              >
                Read full bio
              </Button>
            </>
          )}
        </Col>
        <Col xs={12} sm={6} md={4}>
          <div className={styles.sidebar}>
            <div>
              <DetailsBox className="p-0">
                {!!teamMember.photo?.thumbnails?.small && (
                  <ResponsiveImage
                    image={teamMember.photo?.thumbnails?.small}
                    alt={teamMember.photo?.alt || teamMember.name.text}
                    className={styles.photo}
                  />
                )}
              </DetailsBox>
              <DetailsBox className={styles.detailsBox}>
                <div>
                  <b>{teamMember.name.text}</b>
                  <div>{teamMember.job_title}</div>
                  <div>{teamMember.company_name}</div>
                </div>
              </DetailsBox>
              {!!teamMember.email && (
                <DetailsBox className={styles.detailsBox}>
                  <div className="text-uppercase">Email</div>
                  <a href={`mailto:${teamMember.email}`} className="ml-auto">
                    {teamMember.email}
                  </a>
                </DetailsBox>
              )}
              <DetailsBox className="px-0">
                {teamMember.facebook.url && (
                  <a
                    href={teamMember.facebook.url}
                    title="Facebook"
                    target="blank"
                    className={classNames('link-dark', styles.socialLink)}
                  >
                    <Facebook />
                  </a>
                )}

                {teamMember.instagram.url && (
                  <a
                    href={teamMember.instagram.url}
                    title="Instagram"
                    target="blank"
                    className={classNames('link-dark', styles.socialLink)}
                  >
                    <Instagram />
                  </a>
                )}

                {teamMember.linkedin.url && (
                  <a
                    href={teamMember.linkedin.url}
                    title="LinkedIn"
                    target="blank"
                    className={classNames('link-dark', styles.socialLink)}
                  >
                    <LinkedIn />
                  </a>
                )}

                {teamMember.twitter.url && (
                  <a
                    href={teamMember.twitter.url}
                    title="Twitter"
                    target="blank"
                    className={classNames('link-dark', styles.socialLink)}
                  >
                    <Twitter />
                  </a>
                )}
              </DetailsBox>
            </div>

            {uid === 'charlie-odonnell' && (
              <LinkArrow
                href={contactPath()}
                block={true}
                textLarge={true}
                className={styles.contactButton}
              >
                Request a meeting
              </LinkArrow>
            )}

            {uid === 'lauren-magnuson' && !!contactZapierUrl && (
              <>
                <Button onClick={toggleContactModal} block={true} className={styles.contactButton}>
                  Message Lauren
                  <span className="ml-auto">
                    <ArrowRight />
                  </span>
                </Button>
                <TeamContactModal
                  showContactModal={showContactModal}
                  onClose={() => setShowContactModal(false)}
                  contactSuccessMessage={contactSuccessMessage}
                  contactZapierUrl={contactZapierUrl}
                />
              </>
            )}
          </div>
        </Col>
      </Row>
      {highlights.length > 0 && (
        <div className={styles.highlights}>
          <Highlights highlights={highlights} type="company" />
        </div>
      )}
      <hr />
    </div>
  )
}

const Team = (props: BbvPageProps<PrismicTeamProps>) => {
  const settings = props.data?.prismicSettings?.data
  const data = props.data.prismicTeam.data
  const team_member_list = data.team_member_list.filter((entry) => !!entry.team_member?.document)
  return (
    <Layout title={data.title.text} pageData={props.data}>
      <div className="page-container gray-container">
        <Container>
          <RichText render={data.title.raw} serializeHyperlink={GatsbyLink} />
          <hr />
          <div>
            {team_member_list.map((entry) => (
              <TeamMemberItem
                key={entry.team_member.document.uid}
                uid={entry.team_member.document.uid}
                teamMember={entry.team_member.document.data}
                contactSuccessMessage={data.contact_success_message}
                contactZapierUrl={settings?.contact_zapier_url}
              />
            ))}
          </div>
        </Container>
      </div>
    </Layout>
  )
}

export default withPreview(Team)
