import { formatISO } from 'date-fns'
import { Link } from 'gatsby'
import React, { useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import TextField from 'src/components/text-field'
import { privacyPath } from 'src/paths'
import * as styles from './team-contact-modal.module.scss'

const TeamContactModal = ({
  showContactModal,
  onClose,
  contactSuccessMessage,
  contactZapierUrl,
}: {
  showContactModal: boolean
  onClose: () => void
  contactSuccessMessage: string
  contactZapierUrl: string
}) => {
  const [state, setState] = useState<'idle' | 'loading' | 'success'>('idle')

  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')

  const onSuccess = () => {
    setName('')
    setEmail('')
    setMessage('')
    setState('success')
    setTimeout(() => {
      onClose()
    }, 5000)
  }

  const onError = () => {
    if (state === 'loading') {
      alert('Your request has not been submitted. Please try again.')
      setState('idle')
    }
  }

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    setState('loading')

    fetch(contactZapierUrl, {
      method: 'POST',
      body: JSON.stringify({ name, email, message, timestamp: formatISO(new Date()) }),
    })
      .then(() => {
        onSuccess()
      })
      .catch(() => {
        onError()
      })
  }

  return (
    <Modal
      show={showContactModal}
      centered={true}
      onHide={onClose}
      className={styles.teamContactModal}
      onExited={() => setState('idle')}
    >
      <Modal.Header closeButton>
        <Modal.Title>Contact Lauren</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {state === 'success' ? (
          <div className={styles.successContainer}>
            <div className={styles.successMessage}>{contactSuccessMessage}</div>
            <Button
              onClick={onClose}
              variant="outline-dark"
              block={true}
              className="text-uppercase"
            >
              Close
            </Button>
          </div>
        ) : (
          <form onSubmit={onSubmit}>
            <div className="d-flex flex-column flex-grow-1 mb-4">
              <TextField
                value={name}
                onChange={(e) => setName(e.target.value)}
                type="text"
                label="Full Name"
                required={true}
              />
              <TextField
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                type="email"
                label="Email Address"
                required={true}
              />
              <TextField
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                type="text"
                label="How can I help you?"
                required={true}
              />
            </div>
            <Button
              type="submit"
              block={true}
              className="text-uppercase"
              disabled={state === 'loading'}
            >
              Submit
            </Button>
            <div className={styles.contactModalFooter}>
              By clicking the button I agree with the collection and processing of my personal data
              as described in the{' '}
              <Link to={privacyPath()} className="link-dark">
                Privacy Policy
              </Link>
              .
            </div>
          </form>
        )}
      </Modal.Body>
    </Modal>
  )
}

export default TeamContactModal
